module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"British Columbia - An Untold History","short_name":"BC - An Untold History","start_url":"/","background_color":"#f8f8f8","display":"standalone","lang":"en","icon":"static/icon/favicon-32x32.png","theme_color_in_head":false,"crossOrigin":"use-credentials","icons":[{"src":"static/icon/apple-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/icon/apple-icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"c17696c21a604bb29bc503f8654db4eb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N67BK5X","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"dataLayerName":"dataLayer","routeChangeEventName":"pageview","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
