/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import "./src/styles/scss/components/_preloader.scss";
// import "normalize.css";
import { gsap } from "gsap";
import 'animate.css/animate.min.css';
require("./src/styles/main-style.css");
import "jquery/dist/jquery.min.js";
import "@popperjs/core/dist/umd/popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const $ = require("jquery");
window.jQuery = $;
require("@fancyapps/fancybox");
require("@fancyapps/fancybox/dist/jquery.fancybox.min.css");

export const onClientEntry = () => {
	$("body").addClass('is-loading');

	window.sessionStorage.setItem('showFilter', false);
};

export const onInitialClientRender = () => {
	window.sessionStorage.setItem('showFilter', false);
};

export const onRouteUpdate = ({ location }) => {

	if (typeof window !== `undefined`) {

		window.addEventListener('scroll', () => {
			if (location.pathname !== "/" && window.pageYOffset > 0) {
				document.getElementById('site-header').classList.add("header-fixed-style");
			} else {
				document.getElementById('site-header').classList.remove("header-fixed-style");
			}
		});
	}

	window.setTimeout(() => {
		$('.preloader-content').addClass('animate__animated animate__fast animate__fadeOut');

		$('#preloader').removeClass('animate__fadeIn').addClass('animate__delay-1s animate__fadeOut');
	}, 2000);

	window.setTimeout(() => {
		$('#preloader').remove();
		$('body').removeClass('is-loading').addClass('is-loaded');

		window.sessionStorage.setItem('showFilter', true);
	}, 2500);

	if (document.querySelectorAll('.reveal-animate') && document.querySelectorAll('.reveal-animate').length > 0) {
		gsap.from(
			document.querySelectorAll('.reveal-animate'),
			{
				autoAlpha: 0,
				y: '+=50',
				duration: .5,
				stagger: 0.1,
				force3D: true,
				ease: "power1.easeInOut",
				delay: $('#preloader').length > 0 ? 3 : 1
			},
		)

	}

	var options = {
		selector: ".story-main-slider .slick-slide:not(.slick-cloned) a",
		backFocus: false,
		autoFocus: false,
		trapFocus: false,
		protect: true,
		buttons: [
			"zoom",
			"share",
			// "slideShow",
			"fullScreen",
			// "download",
			"thumbs",
			"close",
		],
		loop: true,
		share: {
			url: function (instance, item) {
				const url = window.location.hash ? window.location.href : window.location.href + '#' + instance.$trigger[0].getAttribute("data-slide-img-id");

				return url;
			},
			tpl:
				'<div class="fancybox-share">' +
				"<h1>{{SHARE}}</h1>" +
				"<p>" +
				'<a class="fancybox-share__button fancybox-share__button--fb" href="https://www.facebook.com/sharer/sharer.php?u={{url}}">' +
				'<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m287 456v-299c0-21 6-35 35-35h38v-63c-7-1-29-3-55-3-54 0-91 33-91 94v306m143-254h-205v72h196" /></svg>' +
				"<span>Facebook</span>" +
				"</a>" +
				'<a class="fancybox-share__button fancybox-share__button--tw" href="https://twitter.com/intent/tweet?url={{url}}&text={{descr}}">' +
				'<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m456 133c-14 7-31 11-47 13 17-10 30-27 37-46-15 10-34 16-52 20-61-62-157-7-141 75-68-3-129-35-169-85-22 37-11 86 26 109-13 0-26-4-37-9 0 39 28 72 65 80-12 3-25 4-37 2 10 33 41 57 77 57-42 30-77 38-122 34 170 111 378-32 359-208 16-11 30-25 41-42z" /></svg>' +
				"<span>Twitter</span>" +
				"</a>" +
				"</p>" +
				'<p><input class="fancybox-share__input" type="text" value="{{url_raw}}" /></p>' +
				"</div>",
		},
	};

	$.fancybox.defaults.hash = false;

	$().fancybox(options);

	if (location.hash) {
		var activeSlide = $(".story-main-slider .slick-slide:not(.slick-cloned) " + location.hash + " a[data-fancybox]");

		$(activeSlide).trigger("click");
	}
};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
	if (location.search) {
		window.setTimeout(() => window.scrollTo(0, 0), 100);
	} else {
		const savedPosition = getSavedScrollPosition(location);
		console.log(savedPosition);
		window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), 100);
	}

	return false;
};